import React, { Component } from 'react';
import './App.css';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import Backdrop from './components/Backdrop/Backdrop';
import Toolbar from './components/Toolbar/Toolbar';
import SideDrawer from './components/SideDrawer/SideDrawer';
import Banner from './components/Banner/Banner';
import Plans from './components/Plans/Plans';
import OrderForm from './components/OrderForm/OrderForm';
import Features from './components/Features/Features';
import Platforms from './components/Platforms/Platforms';
import Footer from './components/Footer/Footer';
import ScrollToTop from 'react-scroll-to-top';
import CookieConsent from 'react-cookie-consent';

import PrivacyPolicy from './components/PrivacyPolicy/PrivacyPolicy';
import TermsAndConditions from './components/TermsAndConditions/TermsAndConditions';

class App extends Component {
  componentDidMount = () => {
    document.title = 'Inkless - asistentul modern al școlii tale';
  };

  state = {
    sideDrawerOpen: false,
    orderFormSelection: '0'
  };

  drawerToggleClickHandler = () => {
    this.setState(prevState => {
      return { sideDrawerOpen: !prevState.sideDrawerOpen };
    });
  };

  backdropClickHandler = () => {
    this.setState({ sideDrawerOpen: false });
  };

  closeSideDrawer = () => {
    this.setState({ sideDrawerOpen: false });
  };

  updateOrderFormSelection = n => {
    this.setState({ orderFormSelection: n });
  };

  mainPage = backdrop => (
    <div style={{ height: '100%' }} className="App">
      <ScrollToTop smooth color="#7a6bee" />
      <Toolbar drawerClickHandler={this.drawerToggleClickHandler} />
      <SideDrawer show={this.state.sideDrawerOpen} close={this.closeSideDrawer} />
      {backdrop}
      <main>
        <Banner id="acasă" />
        <Plans id="prețuri" order={this.updateOrderFormSelection} />
        <OrderForm id="comandă" selected={this.state.orderFormSelection} />
        <Features />
        <Platforms />
        <Footer />
      </main>
    </div>
  );

  render() {
    let backdrop;

    if (this.state.sideDrawerOpen) {
      backdrop = <Backdrop click={this.backdropClickHandler} />;
    }

    return (
      <Router>
        <CookieConsent
          enableDeclineButton
          location="bottom"
          buttonText="Accept"
          cookieName="CookieConsent"
          style={{ background: '#f85568' }}
          buttonStyle={{ color: '#4e503b', fontSize: '13px' }}
          expires={150}
          declineButtonClasses="cookie-consent-details"
          declineButtonText="Detalii"
          buttonClasses="cookie-consent-accept"
          containerClasses="cookie-consent-container"
          onAccept={() => {
            document.cookie = 'CookieConsent=true';
          }}
          onDecline={() => {
            window.location.href = '/politica-de-confidențialitate';
          }}
          acceptOnScroll
          acceptOnScrollPercentage="50"
        >
          <p style={{ marginBlockStart: '0em', marginBlockEnd: '0em', lineHeight: '1.6' }}>
            Acest website utilizează module cookie pentru a vă oferi cele mai bune servicii. Continuând să
            navigați pe site-ul inkless.ro acceptați utilizarea modulelor cookie sau a unor tehnologii
            similare utilizate pentru a realiza statistici și a măsura numarul de vizitatori al site-ului
            nostru.
          </p>
        </CookieConsent>
        <Switch>
          <Route path="/politica-de-confidențialitate">
            <PrivacyPolicy />
          </Route>
          <Route path="/termeni-și-condiții">
            <TermsAndConditions />
          </Route>
          <Route path="/users"></Route>
          <Route path="/">{this.mainPage(backdrop)}</Route>
        </Switch>
      </Router>
    );
  }
}

export default App;
