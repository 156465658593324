import React, { useEffect } from 'react';
import './PrivacyPolicy.css';
import { Link } from 'react-router-dom';
import Footer from './../Footer/Footer';

const PrivacyPolicy = props => {
  useEffect(() => {
    document.title = 'Inkless - politica de confidențialitate';
  });
  return (
    <div className="privacy-policy">
      <header>
        <h2>
          <Link to="/">INKLESS</Link>
        </h2>
        <div className="page-title">
          <h1>Politica de confidențialitate</h1>
        </div>
      </header>
      {/**/}
      {/**/}
      <div className="content-wrapper">
        <div className="content">
          <ol type="A" className="section-list responsive-ol">
            <li>
              <h3 className="title">Introducere</h3>
              <ol className="article-list responsive-ol">
                <li>
                  <p className="article">
                    Confidențialitatea vizitatorilor website-ului nostru și aplicației noastre este foarte
                    importantă pentru noi și ne luăm angajamentul să o protejăm. Această politică explică ce
                    vom face cu informațiile dumneavoastră personale.
                  </p>
                </li>
                <li>
                  <p className="article">
                    Acordarea consimțământului asupra utilizării cookie-urilor în concordanță cu termenii
                    acestei politici atunci când vizitați website-ul nostru pentru prima dată, ne permite să
                    utilizăm cookie-uri de fiecare dată când vizitați website-ul nostru.
                  </p>
                </li>
              </ol>
            </li>
            <li>
              <h3 className="title">Credit</h3>
              <ol className="article-list responsive-ol">
                <p className="article">
                  Acest document a fost creat folosind un șablon de la SEQ Legal (seqlegal.com) și modificat
                  de către Software by Amber Tech SRL.
                </p>
              </ol>
            </li>
            <li>
              <h3 className="title">Colectarea informațiilor personale</h3>

              <ol className="article-list responsive-ol">
                <p className="article">
                  Pot fi colectate, stocate și utilizate următoarele tipuri de informații personale:
                </p>
                <li>
                  <p className="article">
                    informații despre computer, inclusiv adresa IP, locația geografică, tipul și versiunea
                    browser-ului și despre sistemul de operare;
                  </p>
                </li>
                <li>
                  <p className="article">
                    informații despre vizitele și utilizarea acestui website, inclusiv sursa de recomandare,
                    durata vizitei, vizualizările paginii și căile de navigare pe website;
                  </p>
                </li>
                <li>
                  <p className="article">
                    informații precum adresa dvs. de e-mail, numele și prenumele pe care le introduceți atunci
                    când vă înregistrați pe website-ul/aplicația noastră sau care vă sunt introduse de către
                    administratorii aplicației/administratorul școlii-client (procesul se încheie numai cu
                    acordul dvs.);
                  </p>
                </li>
                <li>
                  <p className="article">
                    informații pe care le introduceți în timp ce utilizați serviciile de pe
                    website-ul/aplicația noastră;
                  </p>
                </li>
                <li>
                  <p className="article">
                    informații care sunt generate în timp ce utilizați website-ul/aplicația noastră, inclusiv
                    despre când, cât de des și în ce circumstanțe îl utilizați;
                  </p>
                </li>
                <li>
                  <p className="article">
                    informații conținute în orice comunicări ne trimiteți prin e-mail sau prin intermediul
                    website-ului nostru, inclusiv conținutul comunicărilor și metadatele acestora;
                  </p>
                </li>
                <li>
                  <p className="article">orice alte informații personale pe care ni le trimiteți.</p>
                </li>
              </ol>
            </li>
            <li>
              <h3 className="title">Utilizarea informațiilor dvs. personale</h3>

              <ol className="article-list responsive-ol">
                <p className="article">
                  Informațiile personale transmise prin intermediul website-ului/aplicației noastre vor fi
                  utilizate în scopurile specificate de această politică sau în paginile respective ale
                  website-ului. Putem folosi informațiile dvs. personale pentru:
                </p>
                <li>
                  <p className="article">administrarea website-ului/aplicației și a afacerii noastre;</p>
                </li>
                <li>
                  <p className="article">personalizarea website-ului/aplicației noastre pentru dvs.;</p>
                </li>
                <li>
                  <p className="article">
                    autorizarea utilizării serviciilor disponibile pe website-ul/aplicația noastră;
                  </p>
                </li>
                <li>
                  <p className="article">
                    furnizarea serviciilor achiziționate prin intermediul website-ului/aplicației noastre;
                  </p>
                </li>
                <li>
                  <p className="article">
                    trimiterea de rapoarte, facturi și notificări de plată către dvs. și colectarea plăților
                    de la dvs.;
                  </p>
                </li>
                <li>
                  <p className="article">trimiterea de comunicări comerciale în vederea informării;</p>
                </li>
                <li>
                  <p className="article">trimiterea prin e-mail de notificări solicitate în mod expres;</p>
                </li>
                <li>
                  <p className="article">
                    trimiterea de comunicări de marketing referitoare la afacerea noastră sau la companiile
                    unor terți selectați cu atenție, care considerăm că vă pot interesa, prin poștă sau, acolo
                    unde ați consimțit în mod specific, prin e-mail sau tehnologii similare (ne puteți informa
                    în orice moment dacă nu mai doriți să primiți comunicări de marketing);
                  </p>
                </li>
                <li>
                  <p className="article">
                    furnizarea către terți a informațiilor statistice despre utilizatorii noștri (acești terți
                    nu vor putea identifica niciun utilizator cu ajutorul acestor informații);
                  </p>
                </li>
                <li>
                  <p className="article">
                    abordarea solicitărilor și reclamațiilor făcute de dvs. sau despre dvs. referitoare la
                    website-ul/aplicația noastră;
                  </p>
                </li>
                <li>
                  <p className="article">
                    păstrarea securității website-ului/aplicației noastre și prevenirea fraudelor;
                  </p>
                </li>
                <li>
                  <p className="article">
                    verificarea respectării termenilor și condițiilor care reglementează utilizarea
                    website-ului/aplicației noastre (inclusiv monitorizarea mesajelor private trimise prin
                    intermediul serviciului nostru de mesagerie privată);
                  </p>
                </li>
                <li>
                  <p className="article">alte utilizări.</p>
                </li>
                <p className="article">
                  Fără consimțământul dvs. expres, nu vom furniza informațiile dvs. personale către terți
                  pentru marketingul direct din partea acestor terți sau din partea oricărei alte terțe părți.
                </p>
              </ol>
            </li>
            <li>
              <h3 className="title">Divulgarea informațiilor personale</h3>

              <ol className="article-list responsive-ol">
                <p className="article">
                  Putem divulga informațiile dvs. personale oricăruia dintre angajații, ofițerii,
                  asigurătorii, consilierii profesioniști, agenții, furnizorii sau subcontractanții noștri
                  după cum este necesar în mod rezonabil pentru scopurile prevăzute în această politică. Putem
                  dezvălui informațiile dvs. personale oricărui membru al grupului nostru de companii (acesta
                  include filialele noastre, compania-mamă și toate filialele sale), după cum este necesar în
                  mod rezonabil pentru scopurile prevăzute în această politică. Putem divulga informațiile
                  dvs. personale:
                </p>
                <li>
                  <p className="article">în măsura în care ni se solicită acest lucru prin lege;</p>
                </li>
                <li>
                  <p className="article">în legătură cu orice procedură legală potențială sau în curs;</p>
                </li>
                <li>
                  <p className="article">
                    pentru a stabili, exercita sau apăra drepturile noastre legale (inclusiv furnizarea de
                    informații către terți în scopul prevenirii fraudelor și reducerea riscului de credit);
                  </p>
                </li>
                <li>
                  <p className="article">
                    către cumpărătorul (sau potențialul cumpărător) al oricărei afaceri sau oricărui bun pe
                    care le-am vândut (sau avem în plan să îl vindem);
                  </p>
                </li>
                <li>
                  <p className="article">
                    oricărei persoane considerăm în mod rezonabil că s-ar putea adresa unei instanțe sau unei
                    alte autorități competente pentru divulgarea informațiilor personale în cazul în care, în
                    opinia noastră rezonabilă, o astfel de instanță sau autoritate ar putea să dispună
                    divulgarea informațiilor personale.
                  </p>
                </li>
                <p className="article">
                  Cu excepția situațiilor prevăzute în această politică, nu vom furniza informațiile dvs.
                  personale către terți.
                </p>
              </ol>
            </li>

            <li>
              <h3 className="title">Păstrarea informațiilor personale</h3>
              <ol className="article-list responsive-ol">
                <li>
                  <p className="article">
                    Această Secțiune G stabilește politicile și procedurile noastre de păstrare a datelor,
                    care sunt concepute pentru a ajuta la asigurarea respectării obligațiilor noastre legale
                    cu privire la păstrarea și ștergerea informațiilor personale.
                  </p>
                </li>
                <li>
                  <p className="article">
                    Informațiile personale pe care le prelucrăm cu orice scop sau scopuri nu vor fi păstrate
                    mai mult decât este necesar pentru acel scop sau scopuri.
                  </p>
                </li>
                <li>
                  <p className="article">
                    În pofida celorlalte dispoziții din această Secțiune G, vom păstra documente (inclusiv
                    documente electronice) care conțin date cu caracter personal:
                  </p>
                </li>
              </ol>
            </li>

            <li>
              <h3 className="title">Securitatea informațiilor dvs. personale</h3>
              <ol className="article-list responsive-ol">
                <li>
                  <p className="article">
                    Vom lua măsuri de precauție tehnice și organizaționale rezonabile pentru a preveni
                    pierderea, utilizarea necorespunzătoare sau modificarea informațiilor dvs. personale.
                  </p>
                </li>
                <li>
                  <p className="article">
                    Vom stoca toate informațiile personale pe care le oferiți pe serverele noastre securizate
                    (protejate prin parolă și firewall).
                  </p>
                </li>
                <li>
                  <p className="article">
                    Ați luat la cunoștință faptul că transmiterea informațiilor pe internet este în mod
                    obișnuit nesigură și nu putem garanta securitatea datelor trimise pe internet.
                  </p>
                </li>
                <li>
                  <p className="article">
                    Sunteți responsabil(ă) pentru păstrarea confidențialității parolei pe care o utilizați
                    pentru accesarea website-ului nostru; nu vă vom solicita niciodată parola (cu excepția
                    momentului când vă conectați pe website-ul/aplicația noastră).
                  </p>
                </li>
              </ol>
            </li>

            <li>
              <h3 className="title">Modificări</h3>
              <ol className="article-list responsive-ol">
                <p className="article">
                  Ne rezervăm dreptul de a actualiza această politică din când în când publicând o versiune
                  nouă pe website-ul nostru. Trebuie să verificați ocazional această pagină pentru a vă
                  asigura că înțelegeți orice modificare adusă acestei politici. Vă putem anunța despre
                  modificările aduse acestei politici prin e-mail.
                </p>
              </ol>
            </li>

            <li>
              <h3 className="title">Drepturile dvs.</h3>
              <ol className="article-list responsive-ol">
                <p className="article">
                  Ne puteți solicita să vă oferim orice informații personale deținem despre dvs. Putem reține
                  informațiile personale pe care le solicitați în măsura permisă de lege. Ne puteți solicita
                  în orice moment să nu prelucrăm informațiile dvs. personale în scopuri de marketing. În
                  practică, de obicei fie vă exprimați acordul expres prealabil cu privire la utilizarea
                  informațiilor dvs. personale în scopuri de marketing, fie vă vom oferi posibilitatea de a
                  renunța la utilizarea informațiilor dvs. personale în scopuri de marketing.
                </p>
              </ol>
            </li>

            <li>
              <h3 className="title">Site-uri terțe</h3>
              <ol className="article-list responsive-ol">
                <p className="article">
                  Site-ul nostru include hyperlink-uri către și detalii despre site-uri terțe. Nu deținem
                  niciun control și nu de facem responsabili pentru politicile și practicile de
                  confidențialitate ale terților.
                </p>
              </ol>
            </li>

            <li>
              <h3 className="title">Actualizarea informațiilor</h3>
              <ol className="article-list responsive-ol">
                <p className="article">
                  Vă rugăm să ne anunțați dacă informațiile personale pe care le deținem despre dvs. trebuie
                  să fie corectate sau actualizate.
                </p>
              </ol>
            </li>
            <li>
              <h3 className="title">Cookie-uri</h3>
              <ol className="article-list responsive-ol">
                <p className="article">
                  Website-ul nostru folosește cookie-uri. Cookie-ul este un fișier care conține un
                  identificator (un șir de litere și numere), care este trimis de un server web către un
                  browser și care este stocat de acel browser. Identificatorul este apoi trimis înapoi la
                  server de fiecare dată când browser-ul solicită o pagină de la acesta. Cookie-urile pot fi
                  cookie-uri „persistente” sau cookie-uri „de sesiune”: cookie-ul persistent va fi stocat de
                  către browser și va rămâne valabil până la data stabilită de expirare, cu excepția cazului
                  în care acesta este șters înainte de data de expirare; pe de altă parte, cookie-ul de
                  sesiune va expira la sfârșitul sesiunii utilizatorului, la închiderea browser-ului.
                  Cookie-urile nu conțin de obicei informații care identifică personal un utilizator, dar
                  informațiile personale pe care le stocăm despre dvs. pot fi legate de informațiile stocate
                  și obținute prin cookie-uri. Pe site-ul nostru folosim doar cookie-uri persistente.
                </p>
                <li>
                  <p className="article">
                    Numele cookie-urilor pe care le folosim pe website-ul nostru și scopurile pentru care sunt
                    utilizate acestea pot fi găsite mai jos:
                  </p>
                  <ol className="article-list">
                    <p className="article">
                      Folosim Google Analytics pe website-ul nostru pentru a recunoaște un computer atunci
                      când un utilizator vizitează website-ul. Google Analytics mai urmărește utilizatorii cât
                      timp navighează pe website, îmbunătățește capacitatea de utilizare a website-ului și
                      analizează utilizarea acestuia.
                    </p>
                  </ol>
                </li>
                <li>
                  <p className="article">
                    Majoritatea browser-elor vă permit să refuzați să acceptați cookie-uri – de exemplu:
                  </p>
                  <ol className="article-list">
                    <li>
                      <p className="article">
                        în Internet Explorer (versiunea 10) puteți bloca cookie-urile utilizând suprascrierea
                        setărilor de manipulare a cookie-urilor, disponibile printr-un clic pe „Instrumente”,
                        „Opțiuni de Internet”, „Confidențialitate”, apoi „Avansat”;
                      </p>
                    </li>
                    <li>
                      <p className="article">
                        în Firefox (versiunea 24) puteți bloca toate cookie-urile făcând clic pe
                        „Instrumente”, „Opțiuni”, „Confidențialitate”, selectând „Utilizați setări
                        personalizate pentru istoric” din meniul derulant și debifând „Acceptați cookie-uri de
                        la website-uri”;
                      </p>
                    </li>
                    <li>
                      <p className="article">
                        în Chrome (versiunea 29), puteți bloca toate cookie-urile accesând meniul
                        „Personalizare și control”, făcând clic pe „Setări”, „Afișați setările avansate” și pe
                        „Setări de conținut”, apoi selectând „Blocați site-urile să trimită orice date” la
                        rubrica „Cookie-uri”.
                      </p>
                    </li>
                  </ol>
                </li>
                <p className="article">
                  Blocarea tuturor cookie-urilor va avea un impact negativ asupra utilizabilității multor
                  website-uri. Dacă blocați cookie-urile, nu veți putea utiliza toate funcțiile de pe
                  website-ul nostru.
                </p>
                <li>
                  <p className="article">Puteți șterge cookie-urile deja stocate pe computer – de exemplu:</p>
                  <ol className="article-list">
                    <li>
                      <p className="article">
                        În Internet Explorer (versiunea 10), trebuie să ștergeți manual fișierele cookie
                        (puteți găsi instrucțiuni despre cum puteți face acest lucru
                        la http://support.microsoft.com/kb/278835 );
                      </p>
                    </li>
                    <li>
                      <p className="article">
                        În Firefox (versiunea 24), puteți șterge cookie-urile făcând clic pe „Instrumente”,
                        „Opțiuni” și „Confidențialitate”, apoi selectând „Utilizați setări personalizate
                        pentru istoric”, făcând clic pe „Afișare cookie-uri”, apoi pe „Ștergeți toate
                        Cookie-urile“;
                      </p>
                    </li>
                    <li>
                      <p className="article">
                        în Chrome (versiunea 29), puteți șterge toate cookie-urile accesând meniul
                        „Personalizare și control”, făcând clic pe „Setări”, „Afișați setările avansate” și pe
                        „Ștergeți datele de navigare”, apoi selectați „Ștergeți cookie-urile și alte date
                        despre website și plugin-uri” și faceți clic pe „Ștergeți datele de navigare”.
                      </p>
                    </li>
                  </ol>
                </li>
                <li>
                  <p className="article">
                    Ștergerea cookie-urilor va avea un impact negativ asupra utilizabilității multor
                    website-uri.
                  </p>
                </li>
              </ol>
            </li>
          </ol>
        </div>
      </div>
      {/**/}
      {/**/}
      <div className="before-footer-container">
        <div className="before-footer"></div>
      </div>
      <Footer home="false" />
    </div>
  );
};

export default PrivacyPolicy;
