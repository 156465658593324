import React, { useEffect } from 'react';
import './TermsAndConditions.css';
import { Link } from 'react-router-dom';
import Footer from './../Footer/Footer';

const TermsAndConditions = props => {
  useEffect(() => {
    document.title = 'Inkless - termeni și condiții';
  });
  return (
    <div className="privacy-policy">
      <header>
        <h2>
          <Link to="/">INKLESS</Link>
        </h2>
        <div className="page-title">
          <h1>Termeni și condiții</h1>
        </div>
      </header>
      {/**/}
      {/**/}
      <div className="content-wrapper">
        <div className="content">
          <ol type="A" className="section-list responsive-ol">
            <li>
              <h3 className="title">Informatii despre site</h3>
              <ol className="article-list responsive-ol">
                <li>
                  <p className="article">
                    Site-ul și domeniul web inkless.ro este proprietatea firmei Software by Amber Tech SRL, cu
                    sediul în Grădina Poștei 36, Arad, 310199, înregistrată la Registrul Comerțului cu numărul
                    J2/1118/2020, C.I.F. RO 43089703, numit pe parcursul acestor termeni și condiții Amber
                    Tech
                    <br />
                    <br />
                    Adrese de contact: Tel: (+40) 772.075.243, email: admin@inkless.ro.
                  </p>
                </li>
                <li>
                  <p className="article">
                    Inkless.ro este un asistent școlar și catalog electronic dezvoltat și menținut de Amber
                    Tech.
                  </p>
                </li>
              </ol>
            </li>
            <li>
              <h3 className="title">Proprietatea Intelectuală</h3>
              <ol className="article-list responsive-ol">
                <li>
                  <p className="article">
                    Conținutul și design-ul inkless.ro, inclusiv design-ul și experiența de utilizare
                    a acestuia precum și bazele de date accesibile prin intermediul său, sunt proprietatea
                    Amber Tech și sunt protejate prin legislația româna in vigoare cu privire la drepturile de
                    autor si drepturile conexe.
                  </p>
                </li>
                <li>
                  <p className="article">
                    În condițiile în care considerați că un anumit conținut aflat pe site-ul inkless.ro
                    încalca drepturile dvs. de proprietate intelectuală, dreptul la viața privată, la
                    publicitate sau alte drepturi personale, sunteți rugat să trimiteți un email la
                    admin@inkless.ro cu drepturile încalcate, pentru a permite administratorilor inkless.ro să
                    acționeze în conformitate cu dispozițiile legale conform Legii 365/2002 privind comerțul
                    electronic.
                  </p>
                </li>
                <li>
                  <p className="article">
                    Utilizatorii inkless.ro înțeleg faptul că sunt responsabili de orice conținut publicat de
                    către ei pe platforma oferită de Amber Tech, și că sunt singurii care pot fi trași la
                    răspundere de autoritățile competente pentru încalcarea oricarui act normativ, indiferent
                    că vorbim de mesajele trimise, notele/absențele adăugate pe inkless.ro sau orice alt tip
                    conținut.
                  </p>
                </li>
              </ol>
            </li>
            <li>
              <h3 className="title">Suspendarea accesului</h3>

              <ol className="article-list responsive-ol">
                <p className="article">
                  Amber Tech poate, fără orice altă notificare sau formalitate și fără ca acest lucru să
                  necesite explicarea atitudinii sale, să suspende sau să blocheze accesul dvs. la conținutul
                  site-ului sau la o parte a acestui conținut.
                </p>
              </ol>
            </li>

            <li>
              <h3 className="title">Schimbări ale site-ului</h3>

              <ol className="article-list responsive-ol">
                <p className="article">
                  Amber Tech își rezervă dreptul de a suspenda, modifica, adăuga sau șterge în orice moment
                  porțiuni ale conținutului său. De asemenea, Amber Tech își rezervă dreptul de a restricționa
                  accesul utilizatorilor la o parte sau la întregul său conținut.
                </p>
              </ol>
            </li>

            <li>
              <h3 className="title">Înregistrare, parole și responsabilități</h3>

              <ol className="article-list responsive-ol">
                <li>
                  <p className="article">
                    Accesul dvs. la contul de utilizator sau unele facilități din cadrul site-ului necesită
                    înregistrarea dvs. cu un nume de utilizator și o parolă. Vă recomandăm să nu dezvăluiți
                    nimănui această parolă. Amber Tech nu vă va cere niciodată parola conturilor dvs. prin
                    mesaje, poștă electronică sau telefon.
                  </p>
                </li>
                <li>
                  <p className="article">
                    Din păcate, nici o transmisie de date prin intemediul internetului nu poate fi garantată a
                    fi 100% sigură. In consecință, în ciuda eforturilor noastre de a vă proteja informația
                    personală, Amber Tech nu poate asigura sau garanta securitatea informațiilor transmise de
                    dvs. către noi, către și de la serviciile noastre online. Vă avertizăm așadar că orice
                    informație trimisă către noi se va face pe propriul dvs. risc.
                  </p>
                </li>
              </ol>
            </li>
            <li>
              <h3 className="title">Viața privată</h3>

              <ol className="article-list responsive-ol">
                <p className="article">
                  Amber Tech respectă viața dvs. privată și datele cu caracter personal pe care le împărtășiți
                  cu noi în momentul accesării acestui site. Aceste informații au rolul de a vă informa cu
                  privire la ce tip de informație identificabilă personal este colectată de la dvs., cum și
                  unde am putea folosi această informație, cum înțelegem noi să protejăm informația colectată
                  de la dvs., cine are acces la informația colectată de la dvs. și cum pot fi corectate în
                  timp inadvertentele din informația preluată. Am respecta legislația română în vigoare, în
                  speța Legea nr. 677/2001 pentru protecția persoanelor cu privire la prelucrarea datelor cu
                  caracter personal și libera circulație a acestor date, modificată si completată și Legea nr.
                  506/2004 privind prelucrarea datelor cu caracter personal și protectia vieții private în
                  sectorul comunicațiilor electronice. Pentru mai multe detalii legate de colectarea datelor
                  si de modulele cookie, vă rugăm să consultați politica de confidențialitate.
                </p>
              </ol>
            </li>

            <li>
              <h3 className="title">Modificarea prezentelor Termeni si Condiții</h3>
              <ol className="article-list responsive-ol">
                <p className="article">
                  Amber Tech își rezervă dreptul să schimbe acești termeni, modificând versiunea și data
                  adoptării noului regulament fara îndeplinirea unor alte formalități. Atunci când acești
                  termeni se vor modifica, Amber Tech vă va putea notifica prin intermediul email-ului sau
                  printr-un mesaj în contul dvs. de utilizator al inkless.ro și prin publicarea în prima
                  pagină a site-ului, a unui link care face trimitere la noua formă a prezentului document.
                  Accesul în site, în contul dvs. de utilizator și folosirea serviciilor noastre dupa momentul
                  notificării sau al afișării link-ului privind schimbările pe prima pagină a site-ului
                  implică faptul ca v-ați dat acordul asupra noilor termeni.
                </p>
              </ol>
            </li>

            <li>
              <h3 className="title">Legea guvernantă</h3>
              <ol className="article-list responsive-ol">
                <p className="article">
                  Drepturile și obligațiile părților, impuse de prezentele termeni și condiții, precum și
                  toate efectele juridice pe care le produce prezentul acord vor fi interpretate și guvernate
                  de legea română in vigoare. Orice litigiu care are ca obiect acest acord va fi adus spre
                  soluționare în fața unui arbitru, iar în condițiile în care părțile nu cad de acord asupra
                  numirii unui arbitru în termen de 15 zile de la notificarea litigiului, competența va reveni
                  instantelor românești de la sediul Amber Tech.
                </p>
              </ol>
            </li>
          </ol>
        </div>
      </div>
      {/**/}
      {/**/}
      <div className="before-footer-container">
        <div className="before-footer"></div>
      </div>
      <Footer home="false" />
    </div>
  );
};

export default TermsAndConditions;
